import { io } from "socket.io-client";

let socket = {};
let token;
let messages = [
    {
        id: 1,
        from: "system",
        name: "Система",
        topic: "Ошибка",
        text: "Сообщения ещё не были загружены, и возможно не загрузяться. Если это так, то перезапустите сразицу"
    }
];
let toMeMessages = [
    {
        id: 1,
        from: "system",
        name: "Система",
        topic: "Ошибка",
        text: "Сообщения ещё не были загружены, и возможно не загрузяться. Если это так, то перезапустите сразицу"
    }
];
let MyMessages = [
    {
        id: 1,
        from: "system",
        name: "Система",
        topic: "Ошибка",
        text: "Сообщения ещё не были загружены, и возможно не загрузяться. Если это так, то перезапустите сразицу"
    }
];
let getMessagesFuncts = {};
let startedEvent = false;

export function connect(host, port, user) {
    return new Promise((resolve, reject) => {
        socket = io(`${host}:${port}`);

        socket.on("connect", data => {
            socket.on("authenticate", data2 => {
                console.log(data2);

                token = data2.token;
                resolve(data2);
            });

            socket.emit("authenticate", {
                username: user.username,
                password: user.password
            });
        });

        socket.on("get-messages", data => {
            console.log(data);

            if(data.type == "error") {
                toMeMessages = [
                    {
                        id: 1,
                        from: "system",
                        name: "Система",
                        topic: "Ошибка полученная от вебсокета",
                        text: data.message
                    }
                ];
            } else {
                toMeMessages = data.messages.map((message, i) => ({
                    id: message.ID,
                    from: message.from.address,
                    name: message.from.name,
                    topic: message.subject,
                    text: message.html || message.text
                }));
            }

            console.log(toMeMessages);
            formatMessages();
        
            console.log(getMessagesFuncts["get-messages"])
            if(getMessagesFuncts["get-messages"]){
                console.log("dfjdkj")
                getMessagesFuncts["get-messages"](toMeMessages);
                getMessagesFuncts["get-messages"] = null;
            }
        });

        socket.on("get-my-messages", data => {
            console.log(data);

            if(data.type == "error") {
                MyMessages = [
                    {
                        id: 1,
                        from: "system",
                        name: "Система",
                        topic: "Ошибка полученная от вебсокета",
                        text: data.message
                    }
                ];
            } else {
                MyMessages = data.messages.map((message, i) => ({
                    id: message.ID,
                    from: message.from.address,
                    name: message.from.name,
                    topic: message.subject,
                    text: message.html || message.text
                }));
            }

            console.log(MyMessages);
            formatMessages();
        
            console.log(getMessagesFuncts["get-my-messages"])
            if(getMessagesFuncts["get-my-messages"]){
                console.log("dfjdkj")
                getMessagesFuncts["get-my-messages"](MyMessages);
                getMessagesFuncts["get-my-messages"] = null;
            }
        });
    });
}

export function getMessages() {
    if(!socket?.connected) return null;

    getMessagesFuncts = [];

    return Promise.all([
        new Promise((resolve, reject) => {
            getMessagesFuncts["get-messages"] = resolve;

            socket.emit("get-messages", {
                token: token
            });
        }),
        new Promise((resolve, reject) => {
            getMessagesFuncts["get-my-messages"] = resolve;

            socket.emit("get-my-messages", {
                token: token
            });
        })
    ]);
}

export function getMessages2(callback) {
    if(socket?.connected) {
        socket.on("get-messages", callback);
        socket.on("get-my-messages", callback);
    } else {
        setTimeout(() => getMessages2(callback), 1000);
    }
}

function formatMessages() {
    messages = toMeMessages.concat(MyMessages);
}

export {
    socket,
    messages,
    token
};