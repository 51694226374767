import { ConfigProvider } from 'antd'
import React from 'react'

export default function ThemeProvider({ children }) {
    const theme = {
      token: {
        colorBgContainer: '#303030',
        colorIcon: '#fff',
        colorIconHover: '#fff',
        colorText: '#fff',
        colorTextPlaceholder: '#9e9e9e',
        colorBorder: '#9e9e9e',
        colorBgElevated: '#252525'
      },
      components: {
        Input: {
          background: 'red'
        }
      }
    };
    
  return (
    <ConfigProvider theme={theme}>
        {children}
    </ConfigProvider>
  )
}
