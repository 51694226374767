const AuthReducer = (state = { status: null }, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, ...action.payload };
        
        case 'CLEAR_DATA':
            return { status: false }

        default:
            return state
    }
};

export default AuthReducer;