import { AuthCheck, getLocalData } from "./system/AccountManager";
import Home from './pages/Home/Home';
import Login from './pages/Login';
import { useRoutes } from "react-router-dom";
import ThemeProvider from "./components/ThemeProvider";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const routes = [
  {
    path: '/',
    element: <AuthCheck auth={true} component={<Home />} />
  },
  {
    path: '/m/:id',
    element: <AuthCheck auth={true} component={<Home />} />
  },
  {
    path: '/login',
    element: <AuthCheck auth={false} component={<Login />} />
  }
];

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const authData = getLocalData();
    if (authData) {
      setTimeout(() => {
        dispatch({
          type: 'SET_DATA',
          payload: { status: true, ...authData }
        });
      }, 500);
    } else {
      setTimeout(() => {
        dispatch({
          type: 'SET_DATA',
          payload: { status: false }
        });
      }, 100);
    }
  }, []);
  
  const routing = useRoutes(routes);
  return <ThemeProvider>{routing}</ThemeProvider>;
}

export default App;