import React, { useEffect, useState } from 'react';
import { LockOutlined, UserOutlined, GlobalOutlined, ApartmentOutlined } from '@ant-design/icons';
import '../styles/login.style.css';
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { auth } from '../system/AccountManager';

export default function Login() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    // Выполнить асинхронный запрос для получения начальных значений
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${window.location.origin}/api/config`);
        if (!response.ok) {
          throw new Error('Ошибка при получении данных');
        }
        const data = await response.json();
        // Установить начальные значения
        form.setFieldsValue({
          server: data.default_hostname || '',
          port: data.default_hostname_port || ''
        });
      } catch (error) {
        console.error('Ошибка:', error);
      }
    };

    fetchConfig();
  }, []);

  const onFinish = (values) => {
    auth(values).then((data) => {
      dispatch({
        type: 'SET_DATA',
        payload: { status: true, ...values }
      });
    });
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <p className="title">Авторизация</p>
        <Form
          form={form}
          name="login"
          // initialValues={initialValues}
          style={{ width: '65%' }}
          onFinish={onFinish}
        >
          <Form.Item
            name="server"
            rules={[
              {
                required: true,
                message: 'Вы не указали сервер',
              },
            ]}
          >
            <Input prefix={<ApartmentOutlined />} placeholder="Сервер" />
          </Form.Item>

          <Form.Item
            name="port"
            rules={[
              {
                required: true,
                message: 'Вы не указали порт сервера',
              },
            ]}
          >
            <Input prefix={<GlobalOutlined />} placeholder="Порт" />
          </Form.Item>

          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Вы не указали имя пользователя',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Имя пользователя" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Вы не указали пароль',
              },
            ]}
          >
            <Input prefix={<LockOutlined />} type="password" placeholder="Пароль" />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}